import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { BaseDataCustomer } from "@/config/Modules";

const CustomerModule = namespace(BaseDataCustomer.store);

@Component({})
export class BaseDataCustomerStoreMixin extends Vue {
  /**
   * Actions
   */
  @CustomerModule.Action("create")
  protected createBaseDataCustomerItemAction: any;

  @CustomerModule.Action("findOne")
  protected findOneBaseDataCustomerItemAction: any;

  @CustomerModule.Action("update")
  protected updateBaseDataCustomerItemAction: any;

  @CustomerModule.Action("findAll")
  protected findAllBaseDataCustomerItemsAction: any;

  @CustomerModule.Action("resetSave")
  protected resetSaveBaseDataCustomerAction: any;

  @CustomerModule.Action("resetList")
  protected resetListBaseDataCustomerAction: any;

  @CustomerModule.Action("del")
  protected delBaseDataCustomerItemAction: any;

  @CustomerModule.Action("findAllSubresource")
  protected findAllSubresourceBaseDataCustomerItemsAction: any;

  @CustomerModule.Action("setDataList")
  protected setDataListBaseDataCustomerAction: any;

  @CustomerModule.Action("upload")
  protected uploadBaseDataCustomerAction: any;

  /**
   * Getters
   */
  @CustomerModule.Getter("getError")
  protected getBaseDataCustomerError: any;

  @CustomerModule.Getter("getIsLoading")
  protected getBaseDataCustomerIsLoading: any;

  @CustomerModule.Getter("getIsSaving")
  protected getBaseDataCustomerIsSaving: any;

  @CustomerModule.Getter("getDataItem")
  protected getBaseDataCustomerItem: any;

  @CustomerModule.Getter("getSuccess")
  protected getBaseDataCustomerSuccess: any;

  @CustomerModule.Getter("getDataList")
  protected getBaseDataCustomerList: any;

  @CustomerModule.Getter("getTotal")
  protected getBaseDataCustomerTotal: any;

  @CustomerModule.Getter("getDeletedSuccess")
  protected getBaseDataCustomerDeletedSuccess: any;

  @CustomerModule.Getter("getValidationErrors")
  protected getBaseDataCustomerValidationErrors: any;

  /**
   * Mutations
   */
  @CustomerModule.Mutation("IS_LOADING")
  protected setBaseDataCustomerLoading: any;

  @CustomerModule.Mutation("SET_ERROR")
  protected setBaseDataCustomerError: any;

  @CustomerModule.Mutation("SET_SUCCESS")
  protected setBaseDataCustomerSuccess: any;

  @CustomerModule.Mutation("SET_DATA_ITEM")
  protected setBaseDataCustomerItem: any;

  @CustomerModule.Mutation("SET_DATA_LIST")
  protected setBaseDataCustomerList: any;

  @CustomerModule.Mutation("SET_TOTAL")
  protected setBaseDataCustomerTotal: any;

  @CustomerModule.Mutation("SET_DELETED_SUCCESS")
  protected setBaseDataCustomerDeletedSuccess: any;
}
