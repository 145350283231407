
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { BaseDataCustomer } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class CustomerList extends mixins(
  BaseDataCustomerStoreMixin,
  ListMixin
) {
  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  protected get resource(): string {
    return BaseDataCustomer.resource;
  }

  protected loadList(options: ICrudOptions): any {
    return this.findAllBaseDataCustomerItemsAction(options);
  }

  protected init() {
    this.$echo.channel("Customer").listen(".CustomerDeleted", (e: any) => {
      this.onReloadTable();
    });
  }
}
